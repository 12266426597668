import React from 'react'
import { Card, Table } from '../../components/Atoms'
import { useTranslations } from '../../context/TranslationsContext'

export function PokeballDescriptions() {
    const { t } = useTranslations();

    return (
        <Card className="pb-0">
            <Table size='sm' striped="columns" responsive>
                <thead>
                    <tr>
                        <th style={{width: '15%'}}>{t('name')}</th>
                        <th style={{width: '70%'}}>{t('catch rate')}</th>
                        <th style={{width: '8%'}}>{t('price')}</th>
                        <th style={{width: '7%'}}>{t('apricorn')}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <img src={`/pokeball/pokeball.png`} alt={t('pokeball')} style={{width: 20}}></img>
                            {'  ' + t('pokeball')}
                        </td>
                        <td>{t('1x')}</td>
                        <td>{t('$200')}</td>
                        <td>{t('-')}</td>
                    </tr>
                    <tr>
                        <td>
                            <img src={`/pokeball/greatball.png`} alt={t('greatball')} style={{width: 20}}></img>
                            {'  ' + t('greatball')}
                        </td>
                        <td>{t('1.5x')}</td>
                        <td>{t('$600')}</td>
                        <td>{t('-')}</td>
                    </tr>
                    <tr>
                        <td>
                            <img src={`/pokeball/ultraball.png`} alt={t('ultraball')} style={{width: 20}}></img>
                            {'  ' + t('ultraball')}
                        </td>
                        <td>{t('2x')}</td>
                        <td>{t('$1200')}</td>
                        <td>{t('-')}</td>
                    </tr>
                    <tr>
                        <td>
                            <img src={`/pokeball/repeatball.png`} alt={t('repeatball')} style={{width: 20}}></img>
                            {'  ' + t('repeatball')}
                        </td>
                        <td>{t('increases by 0.1x for each successive catch on a specific pokémon up to 2.5×')}</td>
                        <td>{t('$1250')}</td>
                        <td>{t('-')}</td>
                    </tr>
                    <tr>
                        <td>
                            <img src={`/pokeball/luxuryball.png`} alt={t('luxuryball')} style={{width: 20}}></img>
                            {'  ' + t('luxuryball')}
                        </td>
                        <td>{t('1×, doubles the rate in which the contained pokémon receives friendship')}</td>
                        <td>{t('$1000')}</td>
                        <td>{t('-')}</td>
                    </tr>
                    <tr>
                        <td>
                            <img src={`/pokeball/healball.png`} alt={t('healball')} style={{width: 20}}></img>
                            {'  ' + t('healball')}
                        </td>
                        <td>{t('1x')}</td>
                        <td>{t('$300')}</td>
                        <td>{t('-')}</td>
                    </tr>
                    <tr>
                        <td>
                            <img src={`/pokeball/dreamball.png`} alt={t('dreamball')} style={{width: 20}}></img>
                            {'  ' + t('dreamball')}
                        </td>
                        <td>{t('better catch rate if the pokémon has been asleep for more turns, max catch rate of 4x, minimum of 1x')}</td>
                        <td>{t('$1200')}</td>
                        <td>{t('-')}</td>
                    </tr>
                    <tr>
                        <td>
                            <img src={`/pokeball/lureball.png`} alt={t('lureball')} style={{width: 20}}></img>
                            {'  ' + t('lureball')}
                        </td>
                        <td>{t('4x if used on a pokémon hooked by a fishing rod')}</td>
                        <td>{t('-')}</td>
                        <td>{t('blue')}</td>
                    </tr>
                    <tr>
                        <td>
                            <img src={`/pokeball/friendball.png`} alt={t('friendball')} style={{width: 20}}></img>
                            {'  ' + t('friendball')}
                        </td>
                        <td>{t('2.5x if used on pokémon which evolve due to happiness')}</td>
                        <td>{t('-')}</td>
                        <td>{t('green')}</td>
                    </tr>
                    <tr>
                        <td>
                            <img src={`/pokeball/levelball.png`} alt={t('levelball')} style={{width: 20}}></img>
                            {'  ' + t('levelball')}
                        </td>
                        <td>{t('4x if used on pokémon who are the same level as yours')}</td>
                        <td>{t('-')}</td>
                        <td>{t('red')}</td>
                    </tr>
                    <tr>
                        <td>
                            <img src={`/pokeball/loveball.png`} alt={t('loveball')} style={{width: 20}}></img>
                            {'  ' + t('loveball')}
                        </td>
                        <td>{t('8x if used on pokémon in the same evolution line as yours and the opposite gender')}</td>
                        <td>{t('-')}</td>
                        <td>{t('pink')}</td>
                    </tr>
                    <tr>
                        <td>
                            <img src={`/pokeball/moonball.png`} alt={t('moonball')} style={{width: 20}}></img>
                            {'  ' + t('moonball')}
                        </td>
                        <td>{t('4x if used on pokémon that evolve using a moon stone')}</td>
                        <td>{t('-')}</td>
                        <td>{t('yellow')}</td>
                    </tr>
                    <tr>
                        <td>
                            <img src={`/pokeball/nestball.png`} alt={t('nestball')} style={{width: 20}}></img>
                            {'  ' + t('nestball')}
                        </td>
                        <td>{t('(7 - (0.2 * (level - 1))×: 1x minimum, 4× maximum rate')}</td>
                        <td>{t('$1000')}</td>
                        <td>{t('-')}</td>
                    </tr>
                    <tr>
                        <td>
                            <img src={`/pokeball/heavyball.png`} alt={t('heavyball')} style={{width: 20}}></img>
                            {'  ' + t('heavyball')}
                        </td>
                        <td>{t('better catch rate if the pokémon is heavier, max catch rate of 4x, minimum of 1x')}</td>
                        <td>{t('-')}</td>
                        <td>{t('black')}</td>
                    </tr>
                    <tr>
                        <td>
                            <img src={`/pokeball/premierball.png`} alt={t('premierball')} style={{width: 20}}></img>
                            {'  ' + t('premierball')}
                        </td>
                        <td>{t('1.5x')}</td>
                        <td>{t('-')}</td>
                        <td>{t('-')}</td>
                    </tr>
                    <tr>
                        <td>
                            <img src={`/pokeball/fastball.png`} alt={t('fastball')} style={{width: 20}}></img>
                            {'  ' + t('fastball')}
                        </td>
                        <td>{t('4x if the pokémon has a higher base speed')}</td>
                        <td>{t('-')}</td>
                        <td>{t('white')}</td>
                    </tr>
                    <tr>
                        <td>
                            <img src={`/pokeball/netball.png`} alt={t('netball')} style={{width: 20}}></img>
                            {'  ' + t('netball')}
                        </td>
                        <td>{t('3.5× if used on a water or bug type pokémon')}</td>
                        <td>{t('$1350')}</td>
                        <td>{t('-')}</td>
                    </tr>
                    <tr>
                        <td>
                            <img src={`/pokeball/duskball.png`} alt={t('duskball')} style={{width: 20}}></img>
                            {'  ' + t('duskball')}
                        </td>
                        <td>{t('2.5× if used in a cave or at night')}</td>
                        <td>{t('$1350')}</td>
                        <td>{t('-')}</td>
                    </tr>
                    <tr>
                        <td>
                            <img src={`/pokeball/diveball.png`} alt={t('diveball')} style={{width: 20}}></img>
                            {'  ' + t('diveball')}
                        </td>
                        <td>{t('3.5× if used on a water-dwelling pokémon')}</td>
                        <td>{t('$1200')}</td>
                        <td>{t('-')}</td>
                    </tr>
                    <tr>
                        <td>
                            <img src={`/pokeball/timerball.png`} alt={t('timerball')} style={{width: 20}}></img>
                            {'  ' + t('timerball')}
                        </td>
                        <td>{t('(1 + (turn count * 0.30))×, maximum 4× at 11 turns, minimum 1.0× on first turn')}</td>
                        <td>{t('$1200')}</td>
                        <td>{t('-')}</td>
                    </tr>
                    <tr>
                        <td>
                            <img src={`/pokeball/quickball.png`} alt={t('quickball')} style={{width: 20}}></img>
                            {'  ' + t('quickball')}
                        </td>
                        <td>{t('5× if used on the first turn of a battle')}</td>
                        <td>{t('$1200')}</td>
                        <td>{t('-')}</td>
                    </tr>
                    <tr>
                        <td>
                            <img src={`/pokeball/masterball.png`} alt={t('masterball')} style={{width: 20}}></img>
                            {'  ' + t('masterball')}
                        </td>
                        <td>{t('255x')}</td>
                        <td>{t('-')}</td>
                        <td>{t('-')}</td>
                    </tr>
                    <tr>
                        <td>
                            <img src={`/pokeball/cherishball.png`} alt={t('cherishball')} style={{width: 20}}></img>
                            {'  ' + t('cherishball')}
                        </td>
                        <td>{t('2x')}</td>
                        <td>{t('-')}</td>
                        <td>{t('-')}</td>
                    </tr>
                </tbody>
            </Table>
        </Card>
    )
}
