import React from 'react';
import { Image, Stack } from 'react-bootstrap';
import { Search, Typography } from '../Atoms';
import { useTranslations } from '../../context/TranslationsContext';
import { Box } from '@mui/material'

const statuses = ["none","paralyzed","poisoned","burned","asleep","frozen"];

export const StatusSelector = ({ id, defaultValue, text, ...props }) => {
    const { t } = useTranslations()
    return (
        <Box style={{ marginTop: '12px' }}>
            <Typography as="p" className='text-muted mb-0' style={{ fontSize: '.8rem', marginBottom: '1px' }}>{t(text)}</Typography>
            <div className='d-flex' style={{ gap: '0.3rem' }}>
                <Search
                    {...props}
                    defaultValue={{
                        value: defaultValue,
                        label: (defaultValue === "none")
                            ?
                                <Stack direction='horizontal'>{t(defaultValue)}</Stack>
                            :
                                <Stack direction='horizontal'><Image src={`/status/${defaultValue}.png`} style={{ width: 20, height: 'auto', marginRight: '8px' }}></Image>{t(defaultValue)}</Stack>
                    }}
                    controlId={id}
                    items={
                        statuses.map((status) => {
                            return (
                                {
                                    value: status,
                                    label: (status === "none")
                                        ?
                                            <Stack direction='horizontal'>{t(status)}</Stack>
                                        :
                                            <Stack direction='horizontal'><Image src={`/status/${status}.png`} style={{ width: 20, height: 'auto', marginRight: '8px' }}></Image>{t(status)}</Stack>
                                }
                            )
                        })
                    }
                />
            </div>
        </Box>
    )
}