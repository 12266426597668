import React, { useState } from 'react'
import { TextField } from '../../components/Atoms'
import { Box } from '@mui/material'
import { Form } from 'react-bootstrap'
import { Page } from '../../components/Page'
import { PageTitle } from '../../components/PageTitle'
import { useTranslations } from '../../context/TranslationsContext'
import { getPokemonByName, getPokemonCatchRate } from '../../utils/pokemon';
import { PokeballDescriptions } from '../../components/CatchRateCalculator/PokeballDescriptions';
import { PokemonSelector } from '../../components/CatchRateCalculator/PokemonSelector';
import { PokeballSelector } from '../../components/CatchRateCalculator/PokeballSelector';
import { StatusSelector } from '../../components/CatchRateCalculator/StatusSelector';

const STATUS = {
    none: 1,
    paralyzed: 1.5,
    poisoned: 1.5,
    burned: 1.5,
    asleep: 2,
    frozen: 2,
}

const POKEBALL = {
    pokeball: 1,
    greatball: 1.5,
    ultraball: 2,
    repeatball: 2.5,
    luxuryball: 2,
    healball: 1,
    dreamball: 4,
    lureball: 4,
    friendball: 2.5,
    levelball: 4,
    loveball: 8,
    moonball: 4,
    nestball: 4,
    heavyball: 4,
    premierball: 1.5,
    fastball: 4,
    netball: 3.5,
    duskball: 2.5,
    diveball: 3.5,
    timerball: 4,
    quickball: 5,
    masterball: 255,
    cherishball: 2
}



const CatchRateCalculator = ({ pageContext, location }) => {
    const { t } = useTranslations()
    const PAGE_TITLE = "Catch Rate Calculator"
    const [name, setName] = useState(location?.state?.name || 'Wooper');
    const [ball, setBall] = useState('pokeball');
    const [misc, setMisc] = useState(1);
    const [imageSrc, setImageSrc] = useState(location?.state?.id?`https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/${location?.state?.id}.png`:'https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/194.png');
    const [level, setLevel] = useState(50);
    const [hp, setHp] = useState(100);
    const [isOneHP, setIsOneHP] = useState(false);
    const [status, setStatus] = useState('none');

    var miscText = 'misc';

    const handleNameChange = (result) => {
        const value = result.value;
        setName(value);
        setImageSrc(`https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/${getPokemonByName(value).id}.png`);
    };

    const handleLevelChange = (event) => {
        const value = parseInt(event.target.value, 10);
        if (value >= 1 && value <= 100) {
            setLevel(value);
        }
    };

    const handleHpChange = (event) => {
        const value = parseFloat(event.target.value);
        if (value >= 1 && value <= 100) {
            setHp(value);
        }
    };

    const handleOneHPChange = (event) => { setIsOneHP(event.target.checked) };

    const handleBallChange = (result) => { setBall(result.value) };

    const handleStatusChange = (result) => { setStatus(result.value) };
    
    const handleMiscChange = (event) => {
        const value = parseInt(event.target.value, 10);
        if (value >= 1 && value <= 100) {
            setMisc(value);
        }
    };


    const calculateRate = () => {
        if (!name) return;

        const calculateProbabilityOfCapture = (maxHP, curHP, rate, ball, status) => {
            var mhp = 3 * maxHP;
            var chp = 2 * curHP;
    
            return (((((mhp - chp) / mhp) * rate) * ball) * status) / 255; // gen 3/4 formula
        }
    
        const calculateHPMax = (baseStat, IV, level) => {
            return Math.floor((((2 * baseStat) + IV ) * level ) / 100) + level + 10;
        }
    
        const calculateHPValue = (maxHP, percentage) => {
            return Math.floor((maxHP * percentage) / 100);
        }

        // const calculateShakeProbability = () => { }
        // const calculateNTriesForPercentage = () => { }
        

        var pokemon = getPokemonByName(name)
        var ballRate = 1;

        switch (ball) {

            case 'repeatball':
                miscText = 'captured in a row';
                ballRate = Math.min(1 + (misc * 0.1), 2.5)
                break;

            case 'dreamball':
                miscText = 'turns asleep';
                if (misc === 1) {
                    ballRate = 1.5;
                } else {
                    ballRate = 4;
                }
                break;
            
            case 'friendball':
                if (pokemon.evolutions.length > 0) {
                    var happiness = false;
                    pokemon.evolutions.forEach(evo => {
                        if (evo.type && evo.type.toLowerCase().includes('happiness')) happiness = true;
                    })
                    if (happiness) { 
                        ballRate = 2.5;
                    } else {
                        ballRate = 1;
                    }
                }
                break;
            
            case 'moonball':
                if (pokemon.evolutions.length > 0) {
                    var moonStone = false;
                    pokemon.evolutions.forEach(evo => {
                        if (evo.item_name && evo.item_name.toLowerCase() === 'moon stone') moonStone = true;
                    })
                    if (moonStone) { 
                        ballRate = 4;
                    } else {
                        ballRate = 1;
                    }
                }
                break;

            case 'nestball':
                if (level < 17) {
                    ballRate = 4;
                    break;
                }
                ballRate = Math.max( 4 - ((level - 16) * 0.2), 1);
                break;

            case 'heavyball':
                var weight = pokemon.weight / 10;
                if (weight < 100) {
                    ballRate = 1;
                } else if (weight < 200) {
                    ballRate = 2;
                } else if (weight < 300) {
                    ballRate = 3;
                } else {
                    ballRate = 4;
                }
                break;

            case 'fastball':
                ballRate = (pokemon.stats.speed >= 100) ? 4 : 1; 
                break;

            case 'netball':
                if (pokemon.types.length > 0) {
                    var works = false;
                    pokemon.types.forEach(type => {
                        if (type.toLowerCase() === 'water' || type.toLowerCase() === 'bug') works = true;
                    })
                    if (works) { 
                        ballRate = 3.5;
                    } else {
                        ballRate = 1;
                    }
                }
                break;

            case 'timerball':
                miscText = 'turn';
                ballRate = Math.min(1 + ((misc - 1) * 0.3), 4);
                break;
            
            default:
                miscText = 'misc';
                ballRate = POKEBALL[ball]; 
                break;
        }

        var minHP = calculateHPMax(pokemon.stats.hp, 0, level)
        var maxHP = calculateHPMax(pokemon.stats.hp, 31, level)
        var curMinHP = isOneHP ? 1 : calculateHPValue(minHP, hp)
        var curMaxHP = isOneHP ? 1 : calculateHPValue(maxHP, hp)
        var catchRate = getPokemonCatchRate(pokemon.id)
        var minRate = calculateProbabilityOfCapture(minHP, curMinHP, catchRate, ballRate, STATUS[status]) * 100;
        var maxRate = calculateProbabilityOfCapture(maxHP, curMaxHP, catchRate, ballRate, STATUS[status]) * 100;

        if (minRate > 100) 
            return "100%";
        else if (minRate.toFixed(1) === maxRate.toFixed(1))
            return minRate.toFixed(1) + '%';
        else
            return(minRate.toFixed(1) + '% - ' + maxRate.toFixed(1) + '%');
    }

    return (
        <Page breadcrumbs={pageContext.breadcrumb} label={PAGE_TITLE}>
            <PageTitle>{t(PAGE_TITLE)}</PageTitle>
        
            <Box>
                <Box component="form" sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '1.5rem' }}>
                    
                    <PokemonSelector id={'pokemon'} defaultValue={name} text={'Pokémon'} onChange={handleNameChange} />

                    <TextField textClassName="text-muted" label="level" name="Level" type="number" value={level} onChange={handleLevelChange}/>

                    <Box component="form" sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between', width: 'auto' }}>
                        <TextField textClassName="text-muted" label="hp %" name="HP" type="number" value={hp} onChange={handleHpChange} style={{ width: 'auto' }}/>
                        <div className="d-flex flex-column custom-textfield" style={{ gap: '.3rem' }}>
                            <Form.Text className='text-muted'>{t("has exactly 1 hp?")}</Form.Text>
                            <Form.Check
                                type="switch"
                                checked={isOneHP}
                                onChange={handleOneHPChange}
                            />
                        </div>
                    </Box>
                
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', mt: 2 }} >
                    <a class="pokemon-name-link" href={"https://pokemondb.net/pokedex/" + name.toLowerCase()} target="_blank" rel="noreferrer">
                        <img src={imageSrc} alt="Selected Item" style={{ width: 160, height: 'auto' }} />
                    </a>
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', mt: 2 }}>
                    
                    <Box component="form" sx={{ display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center'}}>
                        
                        <TextField label="Rate" name="rate" value={calculateRate()} disabled/>
                        
                    </Box>
                </Box>

                <Box component="form" sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '1.5rem' }}>
                    
                    <PokeballSelector id={'pokeball'} defaultValue={ball} text={'Pokéball'} onChange={handleBallChange} />

                    <StatusSelector id={'status'} defaultValue={status} text={'Status'} onChange={handleStatusChange} />
                    {/* <FormItemStatuses id={'status'} defaultValue={status} text={'Status'} onChange={handleStatusChange} /> */}

                    <TextField textClassName="text-muted" label={miscText} name="Misc" type="number" value={misc} onChange={handleMiscChange} disabled={miscText.toLowerCase() === 'misc'}/>
                </Box>
            </Box>

            <br></br>
            <br></br>
            <br></br>

            <PokeballDescriptions />
        </Page>
    )
}

export default CatchRateCalculator