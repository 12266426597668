import React from 'react';
import { Image, Stack } from 'react-bootstrap';
import { Search, Typography } from '../Atoms';
import { useTranslations } from '../../context/TranslationsContext';
import { Box } from '@mui/material'

const pokeballs = ["pokeball","greatball","ultraball","repeatball","luxuryball","healball","dreamball","lureball","friendball","levelball","loveball","moonball","nestball","heavyball","premierball","fastball","netball","duskball","diveball","timerball","quickball","masterball","cherishball"];


export const PokeballSelector = ({ id, defaultValue, text, ...props }) => {
    const { t } = useTranslations()
    return (
        <Box style={{ marginTop: '12px' }}>
            <Typography as="p" className='text-muted mb-0' style={{ fontSize: '.8rem', marginBottom: '1px' }}>{t(text)}</Typography>
            <div className='d-flex' style={{ gap: '0.3rem' }}>
                <Search
                    {...props}
                    defaultValue={{
                        value: defaultValue,
                        label: <Stack direction='horizontal'><Image src={`/pokeball/${defaultValue}.png`} style={{ width: 20, height: 'auto', marginRight: '8px' }}></Image>{t(defaultValue)}</Stack>
                    }}
                    controlId={id}
                    items={
                        pokeballs.map((pokeball) => {
                            return (
                                {
                                    value: pokeball,
                                    label: <Stack direction='horizontal'><Image src={`/pokeball/${pokeball}.png`} style={{ width: 20, height: 'auto', marginRight: '8px' }}></Image>{t(pokeball)}</Stack>
                                }
                            )
                        })
                    }
                />
            </div>
        </Box>
    )
}