import React from 'react';
import { Search, Typography } from '../Atoms';
import { Box } from '@mui/material'

import { POKEMON as pokemons } from '../../utils/pokemon';
import { useTranslations } from '../../context/TranslationsContext';


export const PokemonSelector = ({ id, defaultValue, text, ...props }) => {
    const { t } = useTranslations()
    return (
        <Box style={{ marginTop: '12px' }}>
            <Typography as="p" className='text-muted' style={{ fontSize: '.8rem', marginBottom: '1px' }}>{t(text)}</Typography>
            <Search
                {...props}
                defaultValue={{ value: defaultValue, label: defaultValue }}
                controlId={id}
                items={
                    pokemons.map(pkmn => {
                        return {
                            value: pkmn.name,
                            label: t(pkmn.name)
                        }
                    })
                }
            />
        </Box>
    )
}